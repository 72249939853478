import { color } from "chart.js/helpers";
import http from "../api-http-common";

class AuthService {
  pwdPost(uuid:string | string[],data:any) {
    return http.post("/activations/"+uuid, data);
  }

  pwdChangePost(data:any) {
    return http.post("/password/change", data);
  }

  pwdForgotPost(data:any) {
    return http.post("/password/forgot", data);
  }

  pwdResetPost(uuid:string | string[],data:any) {
    return http.post("/password/reset/"+uuid, data);
  }
  getActivation(uuid:string | string[]) {
    // console.log(uuid);
    return http.get("/activations/"+uuid);
  }

  getProfile() {
    return http.get("/profile");
  }
}

export default new AuthService();
